<template>
    <conta-evento-form acao="INSERIR" @salvar="inserir($event)" :cancelar="cancelar" :errosPai="erros"></conta-evento-form>
</template>

<script>
import ContaEventoForm from './ContaEventoForm';
import ContasEventosServices from './services';

export default {
    components: {
        ContaEventoForm,
    },
    data() {
        return {
            erros: [],
        };
    },

    methods: {
        cancelar() {
            this.$router.push({
                name: 'Checkins_ContasEventos',
            });
        },

        inserir(contaEventoInserida) {
            this.$store.dispatch('addRequest');
            ContasEventosServices.inserir(contaEventoInserida)
                .then((response) => {
                    if (response?.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Contas de Eventos',
                            detail: 'Conta de Evento inserida com sucesso',
                            life: 3000,
                        });
                        this.$store.dispatch('setAtualizar', true);
                        this.$router.push({
                            name: 'Checkins_ContasEventos',
                        });
                    } else {
                        this.erros = response.errors;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
};
</script>
